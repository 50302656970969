import {Button, Col, Dropdown, Form, Modal, Row,} from "@govtechsg/sgds-react";
import Select, {components} from "react-select";
import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {patchApplications,} from "@/apis/applications/api";
import {getRemarkNYP} from "@/apis/preparation/setup-remarks/api";
import {getAcademyYear, getExercise} from "@/apis/timeline/api";
import {getCodeList, saveStudentFinassists} from "@/apis/sims/api";
import CustomModal from "@/components/CustomModal";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {toast} from "react-toastify";
import {postStudentList} from "@/apis/app-records/verification/api";
import {sortDescendingByKey, sortExerciseList} from "@/components/utils/utils";
import {ApplicationStatus, VerificationStatus} from "@/apis/app-records/maintenance/status";
import DataTable from "react-data-table-component";
import {getApplicationList, getApplicationListByAcadYrExercise,} from "@/apis/app-records/maintenance/api";
import {getTimeline,} from "@/apis/preparation/setup-timeline/api";
import {Link} from "react-router-dom";
import {getAwardListWithParams} from '@/apis/preparation/setup-award/api'

export default function BatchUpdate() {
    const [dataAcademyYear, setDataAcademyYear] = useState([]);
    const [dataAdminNo, setDataAdminNo] = useState([]);
    const [dataApplications, setDataApplications] = useState([]);
    const [dataAward, setDataAward] = useState([]);
    const [dataAwardType, setDataAwardType] = useState([]);
    const [dataExercise, setDataExercise] = useState([]);
    const [dataRemark, setDataRemark] = useState([]);

    const [academyYear, setAcademyYear] = useState(null);
    const [adminNo, setAdminNo] = useState(null);
    const [addRemarks, setAddRemarks] = useState({
        appln_status: "A",
        remark: null,
        remarks1: null,
    });
    const [applicationStatus, setApplicationStatus] = useState(null);
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [verificationStatusValue, setVerificationStatusValue] = useState("")
    const [award, setAward] = useState(null);
    const [awardType, setAwardType] = useState(null);
    const [exercise, setExercise] = useState(null);

    const [isOpen, setIsOpen] = useState(true);
    const [showDeleteApplication, setShowDeleteApplication] = useState(false);

    const contentRef = useRef(null);

    const [items, setItems] = useState([]);

    const [groupEdit, setGroupEdit] = useState({name: null, age: null});

    const [selectAll, setSelectAll] = useState(false);

    const [show, setShow] = useState(false);

    const [loadingAcademyYear, setLoadingAcademyYear] = useState(true);
    const [loadingListAward, setLoadingListAward] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const refTimeoutSearch = useRef(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [isNextPage, setIsNextPage] = useState(true);
    const [retrieved, setRetrieved] = useState(false)
    const [totalData, setTotalData] = useState(0)

    useEffect(() => {
        handleGetListAcademyYear();
        // handleGetListAdminNo();
        handleGetListAwardType();
        handleGetListRemark();
        setSelectedOptions(
            columnVisibilityOptions.map((option) => ({
                value: option.value,
                label: option.label,
            }))
        );

    }, []);

    useEffect(() => {
        if (academyYear?.value) handleGetListExercise();
    }, [academyYear]);

    useEffect(() => {
        if (exercise) {
            handleGetListAdminNo()
        }
    }, [exercise]);

    useEffect(() => {
        if (awardType?.value) handleGetListAward(awardType?.value);
    }, [awardType]);

    const handleDeleteApplication = () => {
        if (selectedItem) {
            deleteApplication(
                selectedItem?.acad_yr,
                selectedItem?.exercise,
                selectedItem?.adm_no
            )
                .then(() => {
                    handleGetListApplications();
                    setShowDeleteApplication(false);
                    setSelectedItem(null);
                    toast.success("Record deleted successfully");
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        }
    };

    const handleGetListAcademyYear = async () => {
        setLoadingAcademyYear(true);

        try {
            setAcademyYear(null);

            const response = await getAcademyYear();
            const academyYears = response?.data?.data?.map((item) => ({
                label: item?.acad_yr,
                value: item?.acad_yr,
            }))
            const sortedAcademyYears = sortDescendingByKey(academyYears, "value");

            setDataAcademyYear(sortedAcademyYears);

        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingAcademyYear(false);
        }
    };

    const handleGetListAdminNo = async (search = null) => {
        try {
            setAdminNo(null);
            setDataAdminNo([]);
            getApplicationListByAcadYrExercise({
                acad_yr: academyYear.value,
                exercise: exercise.value,
                adm_no: search,
                limit: 20
            }).then(
                (resp) => {
                    if (resp?.response?.data?.message) {
                        if (
                            resp?.response?.data?.message?.length > 0 &&
                            Array.isArray(resp?.response?.data?.message)
                        ) {
                            resp?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(resp?.response?.data?.message);
                        }
                    } else if (resp?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        if (resp.status == 200) {
                            let listContent = [];
                            resp?.data?.data?.map((item) => {
                                if (listContent.some((e) => e?.value === item?.adm_no)) {
                                } else {
                                    listContent.push({value: item?.adm_no, label: item?.adm_no});
                                }
                            });
                            setDataAdminNo(listContent);
                        }
                    }
                }
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListApplications = async () => {
        try {
            const params = {
                acad_yr: academyYear?.value ?? null,
                exercise: exercise?.value ?? null,
                adm_no: adminNo?.value ?? null,
                appln_status: applicationStatus?.value ?? null,
                verify_status: verificationStatusValue ?? null,
                rec_award_type: awardType?.value ?? null,
                rec_award_code: award?.value ?? null,
                page: currentPage,
                limit: itemsPerPage,
            };

            const response = await getApplicationList(params);

            setTotalPages(response?.data?.totalPage);
            setIsNextPage(response?.data?.nextPage);
            setTotalData(response?.data?.totalData)

            if (response?.data?.data?.length === 0) {
                toast.warning("Application data is empty");
                setItems([])
                return;
            }

            const modifiedItems = await Promise.all(
                response?.data?.data?.map(async (item) => {
                    let isHomes = false;

                    // Await the getTimeline call
                    const timelineResponse = await getTimeline(item?.acad_yr, item?.exercise);
                    const timelineData = timelineResponse?.data?.data?.[0];
                    if (timelineData?.homes_tag === 'Y') {
                        isHomes = true;
                    }

                    return {
                        ...item,
                        isHomes: isHomes,
                        name: null
                    };
                })
            );

            setItems(modifiedItems);

            const itemsMap = modifiedItems.reduce((map, item) => {
                map[item.adm_no] = item;
                return map;
            }, {});

            const students_id = response?.data?.data
                ?.map((application) => application.adm_no)
                .join(",");

            if (students_id) {
                postStudentList(students_id, true).then((response2) => {
                    response2?.data?.data?.forEach((student) => {
                        const item = itemsMap[student.admNo];
                        if (item) {
                            //Code here
                            getRemarkNYP(1,1, item.remark).then((response3) => {
                                console.log(response3)
                                setItems((prevItems) =>
                                    prevItems.map((prevItem) => {
                                        if (prevItem.adm_no === item.adm_no) {
                                            return {
                                                ...prevItem,
                                                remark_label: response3?.data?.data?.[0]?.remark,
                                            };
                                        }
                                        return prevItem;
                                    })
                                );
                            })

                            setItems((prevItems) =>
                                prevItems.map((prevItem) => {
                                    if (prevItem.adm_no === item.adm_no) {
                                        return {
                                            ...prevItem,
                                            name: student.name,
                                            course: student?.courseList?.[0],
                                        };
                                    }
                                    return prevItem;
                                })
                            );
                        }
                    });
                });
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListAward = async (codeType = null) => {
        setLoadingListAward(true);

        try {
            setAward(null);

            const response = await getCodeList(codeType);

            setDataAward(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingListAward(false);
        }
    };

    const handleGetListAwardType = async (codeType = "AWARDTYPECODE") => {
        try {
            setAwardType(null);

            const response = await getCodeList(codeType);

            setDataAwardType(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListExercise = async () => {
        const params = {
            acad_yr: academyYear?.value,
        };

        try {
            setExercise(null);

            const response = await getExercise(params);

            if (response?.data?.data?.length > 0) {
                setDataExercise(
                    sortExerciseList(response?.data?.data)?.map((item) => ({
                        label: item?.exercise,
                        value: item?.exercise,
                    }))
                );

            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListRemark = async () => {
        try {

            const response = await getRemarkNYP(0, 1);

            setDataRemark(
                response?.data?.data?.map((item) => ({
                    label: item?.remark,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const updateGroupEditForm = (selectedItems) => {
        if (selectedItems.length === 0) {
            setGroupEdit({name: null, age: null, role: null});
            return;
        }
        const name = selectedItems.map((item) => item.name).join(", ");
        const age = selectedItems.map((item) => item.age).join(", ");
        const role = selectedItems.map((item) => item.role).join(", ");
        setGroupEdit({name, age, role});
    };

    const handleSearch = (searchFunction = null, value = null) => {
        clearTimeout(refTimeoutSearch.current);

        if (value) {
            refTimeoutSearch.current = setTimeout(() => {
                searchFunction(value);
            }, 500);
        }
    };

    const [selectedRows, setSelectedRows] = useState([])

    const handleSelectItem = (row) => {
        setSelectedRows(row.selectedRows)
    };

    const handleChange = (id, field, value) => {
        setItems(
            items.map((item) => {
                if (item.id === id) {
                    return {...item, [field]: value};
                }
                return item;
            })
        );
    };

    const handleDelete = (id) => {
        setItems(items.filter((item) => item.id !== id));
    };

    const handleEdit = (id) => {
        setItems(
            items.map((item) => {
                if (item.id === id) {
                    return {...item, editable: !item.editable};
                }
                return item;
            })
        );
    };

    const handleAddRemarks = async () => {
        console.log("add remark")
        console.log(selectedRows)
        try {
            const appln_status = addRemarks?.appln_status;
            if (appln_status === "A") {
                if (!selectedRows.some(row => row?.rec_award_code) && !selectedRows.some(row => row?.rec_award_type)) {
                    toast.error("Not allowed to approve since one of these application has no award");
                    return;
                }
            }

            selectedRows.map((data) => {
                if (appln_status == "A") {
                    // get amount awarded
                    getAwardListWithParams(data.rec_award_type, data.rec_award_code).then(async (resp) => {
                        if (resp.status == 200) {
                            const quantumValue = resp?.data?.data?.[0]?.quantum;

                            // submit awarded to finanists
                            const studResponse = await saveStudentFinassists([{
                                adm_no: data?.adm_no ?? adminNo?.value,
                                award: data.rec_award_code,
                                award_type: data.rec_award_type,
                                year_awarded: data?.acad_yr ?? academyYear?.value,
                                amt_awarded: quantumValue,
                                action: "I",
                                last_upd_id: sessionStorage.getItem("staffId")
                                    ? sessionStorage.getItem("staffId")
                                    : process.env.REACT_APP_LAST_UPD_ID
                            },])

                            if (studResponse?.data?.status) {
                                //    toast.success("Student Finassist saved successfully")
                            } else {
                                toast.error(studResponse?.data?.details?.[0]?.error)
                            }

                        }
                    })
                }
            })

            const params = {
                data: [
                    ...selectedRows.map((data) => ({
                        acad_yr: data?.acad_yr ?? academyYear?.value,
                        exercise: data?.exercise ?? exercise?.value,
                        adm_no: data?.adm_no ?? adminNo?.value,
                        // VERIFY_STATUS: addRemarks?.appln_status?.value,
                        appln_status: addRemarks?.appln_status,
                        remark: addRemarks?.remark?.value,
                        remarks1: addRemarks?.remarks1,
                        last_upd_id: sessionStorage.getItem('staffId') ? sessionStorage.getItem('staffId') : process.env.REACT_APP_LAST_UPD_ID,
                    })),
                ],
            };

            const resp = await patchApplications(params);

            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success("Application has been saved");
                }
            }

            setAddRemarks({
                appln_status: "A",
                remark: null,
                remarks1: null,
            });

            setShow(false);
            setSelectedItem([])
            handleGetListApplications()
        } catch (error) {
            console.log(error?.message);
        }
    };

    const [columnVisibilityOptions, setColumnVisibilityOptions] = useState([
        {
            value: "age",
            label: "SNo",
            visible: true,
            columnEditable: false
        },
        {
            value: "adm_no",
            label: "Adm. No.",
            visible: true,
            columnEditable: false,
        },
        {
            value: "name",
            label: "Name",
            visible: true,
            columnEditable: false
        },
        {
            value: "courseName",
            label: "Course",
            visible: true,
            columnEditable: false,
        },
        {
            value: "studyStage",
            label: "Study Stage",
            visible: true,
            columnEditable: false,
        },
        {
            value: "mthly_income",
            label: "Income",
            visible: true,
            columnEditable: false,
        },
        {
            value: "family_members",
            label: "Members",
            visible: true,
            columnEditable: false,
        },
        {
            value: "dependent",
            label: "Dependant",
            visible: true,
            columnEditable: false,
        },
        {
            value: "mpci",
            label: "MPCI",
            visible: true,
            columnEditable: false,
        },
        {
            value: "gpa",
            label: "GPA",
            visible: true,
            columnEditable: false,
        },
        {
            value: "citizen",
            label: "Citizen",
            visible: true,
            columnEditable: false,
        },
        {
            value: "physical_status",
            label: "PR",
            visible: true,
            columnEditable: false
        },
        {
            value: "homehousetype",
            label: "Housing",
            visible: true,
            columnEditable: false,
        },
        {
            value: "other_income",
            label: "GHI Income",
            visible: true,
            columnEditable: false,
        },
        {
            value: "gross_income",
            label: "Gross Income",
            visible: true,
            columnEditable: false,
        },
        {
            value: "fpci",
            label: "FPCI",
            visible: true,
            columnEditable: false,
        },
        {
            value: "rec_award_type",
            label: "Award Type",
            visible: true,
            columnEditable: false
        },
        {
            value: "rec_award_code",
            label: "Award",
            visible: true,
            columnEditable: false
        },
        {
            value: "verify_status",
            label: "Verification Status",
            visible: true,
            columnEditable: false,
        },
        {
            value: "appln_status",
            label: "Application Status",
            visible: true,
            columnEditable: false,
        },
        {
            value: "appln_status",
            label: "Application Status Remark (NYP)",
            visible: true,
            columnEditable: false,
        },
        {
            value: "remarks1",
            label: "Special Remarks",
            visible: true,
            columnEditable: false,
        },
    ])

    const columns = [
        // { value: "age", label: "SNo", name: "SNo", selector: row => row.age, sortable: true },
        {
            value: "adm_no",
            label: "Adm. No.",
            name: "Adm. No.",
            selector: row => row.adm_no,
            sortable: true,
            cell: row => {
                const url = row.isHomes
                    ? `/app-records/app-homes?academicYearParams=${row.acad_yr}&exerciseParams=${row.exercise}&admNoParams=${row.adm_no}`
                    : `/app-records/app-maintenance?academicYearParams=${row.acad_yr}&exerciseParams=${row.exercise}&admNoParams=${row.adm_no}`;

                return (
                    <Link to={url} target={"_blank"}>
                        {row.adm_no}
                    </Link>
                );
            }
        },
        {value: "name", label: "Name", name: "Name", selector: row => row.name, sortable: true},
        {
            value: "courseName", label: "Course", name: "Course", selector: row => row.courseName,
            cell: row => row?.course?.courseName ?? "-",
            sortable: true
        },
        {
            value: "studyStage",
            label: "Study Stage",
            name: "Study Stage",
            selector: row => parseInt(row?.course ? row?.course?.studyStage !== "" ? row?.course?.studyStage : 0 : 0),
            sortable: true
        },
        {
            value: "ccc_income",
            label: "RHI",
            name: "RHI",
            selector: row => parseFloat(row.ccc_income ?? 0),
            sortable: true
        },
        {
            value: "dependent",
            label: "Dependent",
            name: "Dependent",
            selector: row => parseInt(row.dependent ?? 0),
            sortable: true
        },
        // { value: "dependent", label: "Dependant", name: "Dependant", selector: row => row.dependent, sortable: true },
        {value: "mpci", label: "MPCI", name: "MPCI", selector: row => parseFloat(row.mpci ?? 0), sortable: true},
        {
            value: "gpa",
            label: "GPA",
            name: "GPA",
            selector: row => parseFloat(row?.course ? row?.course?.gpa !== "" ? row?.course?.gpa : 0 : 0),
            sortable: true
        },
        {value: "citizen", label: "Citizen", name: "Citizen", selector: row => row.citizen, sortable: true},
        {value: "physical_status", label: "PR", name: "PR", selector: row => row.physical_status, sortable: true},
        {value: "homehousetype", label: "Housing", name: "Housing", selector: row => row.homehousetype, sortable: true},
        {
            value: "ccc_income",
            label: "CCC Income",
            name: "CCC Income",
            selector: row => parseFloat(row.ccc_income ?? 0),
            sortable: true
        },
        {
            value: "gross_income",
            label: "GHI Income",
            name: "GHI Income",
            selector: row => parseFloat(row.gross_income ?? 0),
            sortable: true
        },
        // { value: "gross_income", label: "Gross Income", name: "Gross Income", selector: row => parseFloat(row.gross_income ?? 0), sortable: true },
        // { value: "fpci", label: "FPCI", name: "FPCI", selector: row => row.fpci, sortable: true },
        {
            value: "rec_award_type",
            label: "Award Type",
            name: "Award Type",
            selector: row => row.rec_award_type,
            sortable: true
        },
        {value: "rec_award_code", label: "Award", name: "Award", selector: row => row.rec_award_code, sortable: true},
        {
            value: "verify_status",
            label: "Verification Status",
            name: "Verification Status",
            selector: row => row.verify_status,
            sortable: true
        },
        {
            value: "appln_status",
            label: "Application Status",
            name: "Application Status",
            selector: row => row.appln_status,
            sortable: true
        },
        {
            value: "remark",
            label: "Application Status Remarks (NYP)",
            name: "Application Status Remarks (NYP)",
            selector: row => row.remark_label,
            sortable: true
        },
        {
            value: "remarks1",
            label: "Special Remarks",
            name: "Special Remarks",
            selector: row => row.remarks1,
            sortable: true
        },
        {
            value: "",
            name: null,
            cell: row =>
                <button
                    className="btn-clear"
                    onClick={() => {
                        handleSelectItem({
                            selectedRows: [row]
                        });
                        setShow(true);
                    }}
                >
                    <i className="bi bi-pencil"></i>
                </button>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];


    const [selectedColumns, setSelectedColumns] = useState(columns.map(col => col.value))

    const filteredColumns = columns.filter(column => selectedColumns.includes(column.value));

    const [selectedOptions, setSelectedOptions] = useState([]);

    const customOption = (props) => (
        <div>
            <components.Option {...props}>
                <input
                    type={"checkbox"}
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );

    const handleSubmitRetrieve = (e) => {
        e.preventDefault()
        setRetrieved(true)
        handleGetListApplications()
    }

    const toggleColumnVisibility = (key) => {
        setColumnVisibilityOptions(
            columnVisibilityOptions.map((column) =>
                column.value === key ? {...column, visible: true} : column
            )
        );
    };

    const handleColumnChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);

        if (!selectedValues.includes("")) {
            selectedValues.push("");
        }

        setSelectedColumns(selectedValues);
    };


    useEffect(() => {
        if (retrieved) {
            handleGetListApplications()
        }
    }, [currentPage, itemsPerPage]);

    useEffect(() => {
        setSelectedOptions(
            columnVisibilityOptions.map((option) => ({
                value: option.value,
                label: option.label,
            }))
        );
    }, []);

    return (
        <Layout currentNav={"processing"} toc={false}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Application Processing</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Batch Application Status Update
                </Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Batch Application Status Update</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className="d-flex gap-4 flex-column">
                    <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                        <div className="row">
                            <div className="col">
                                <h3>Data Retrieval</h3>
                            </div>
                        </div>

                        <Form
                            ref={contentRef}
                            className={` d-flex flex-column gap-3 collapse-animation`}
                            style={{
                                overflow: isOpen ? "unset" : "hidden",
                                maxHeight: isOpen
                                    ? `${
                                        contentRef?.current?.scrollHeight == null
                                            ? "100%"
                                            : contentRef?.current?.scrollHeight
                                    }px`
                                    : "0px",
                            }}
                            onSubmit={(e) => handleSubmitRetrieve(e)}
                        >
                            <Row>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Academic Year</Form.Label>
                                        <Select
                                            onChange={(value) => setAcademyYear(value)}
                                            options={dataAcademyYear}
                                            placeholder={`Academic Year`}
                                            value={academyYear}
                                            isClearable={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Exercise</Form.Label>
                                        <Select
                                            isDisabled={!academyYear?.value}
                                            onChange={(value) => setExercise(value)}
                                            options={dataExercise}
                                            value={exercise}
                                            isClearable={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Adm. No</Form.Label>
                                        <Select
                                            onChange={(value) => setAdminNo(value)}
                                            options={dataAdminNo}
                                            onInputChange={(value) =>
                                                handleSearch(handleGetListAdminNo, value)
                                            }
                                            value={adminNo}
                                            isClearable={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Application Status</Form.Label>
                                        <Select
                                            backspaceRemovesValue={true}
                                            onChange={(value) => setApplicationStatus(value)}
                                            options={ApplicationStatus()}
                                            placeholder={`Application Status`}
                                            value={applicationStatus}
                                            isClearable={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Verification Status</Form.Label>
                                        <Select
                                            backspaceRemovesValue={true}
                                            onChange={(value) => {
                                                setVerificationStatus(value)
                                                setVerificationStatusValue(value?.value)
                                            }}
                                            options={VerificationStatus()}
                                            placeholder={`Verification Status`}
                                            value={verificationStatus}
                                            isClearable={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Award Type</Form.Label>
                                        <Select
                                            onChange={(value) => setAwardType(value)}
                                            options={dataAwardType}
                                            value={awardType}
                                            isClearable={true}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Award</Form.Label>
                                        <Select
                                            isDisabled={!awardType?.value}
                                            onChange={(value) => setAward(value)}
                                            options={dataAward}
                                            value={award}
                                            isClearable={true}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={3}>
                                    <Button type="submit" className="btn btn-primary">
                                        Retrieve
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="btn-clear btn-link ps-0 mt-3 d-flex gap-2 align-items-center"
                        >
                            {isOpen ? "Hide " : "Expand "}{" "}
                            {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                        </button>
                    </div>
                </div>
                {items?.length > 0 ? (
                    <div className="mt-4 p-3 bg-white shadow-sm border rounded border-1">
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Columns visibility</Form.Label>
                                    <Select
                                        isMulti
                                        options={columns.filter(column => column.value)}
                                        onChange={handleColumnChange}
                                        defaultValue={columns.filter(column => column.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col
                                md={6}
                                className="d-flex justify-content-end align-items-end gap-3"
                            >
                                <Dropdown>
                                    <Dropdown.Toggle
                                        className={`btn btn-outline-dark`}
                                        disabled={items?.length <= 0}
                                    >
                                        <i className="bi bi-three-dots-vertical"></i>
                                        Update Status
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setShow(true)}>
                                            Add Remarks
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xs={12}>
                                <DataTable
                                    columns={filteredColumns}
                                    data={items}
                                    onSelectedRowsChange={handleSelectItem}
                                    selectableRows
                                    pagination
                                    paginationRowsPerPageOptions={[20, 100, 500]}
                                    paginationPerPage={itemsPerPage}
                                    onChangeRowsPerPage={(newPerPage, page) => {
                                        setItemsPerPage(newPerPage);
                                    }}
                                    onChangePage={page => setCurrentPage(page)}
                                    paginationTotalRows={totalData}
                                    paginationServer
                                />
                            </Col>
                        </Row>
                    </div>
                ) : null}
            </sgds-content-body>
            <Modal
                size={"lg"}
                show={show}
                onHide={() => setShow(false)}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={`justify-content-center`} style={{maxHeight: "300px", overflowY: "scroll"}}>
                        <Col xs={8} className={`bg-yellow-100 p-2 text-center`}>
                            Selected {selectedRows.map((indv, index) => (
                            <React.Fragment key={index}>
                                {indv.name + " " + indv.adm_no || indv.adm_no}
                                {index < selectedRows.length - 1 && ", "}
                                <br/>
                            </React.Fragment>
                        ))}
                        </Col>
                    </Row>

                    <Row className={`flex-column mt-3`}>
                        <h5 className={`fw-bold`}>Application Status</h5>

                        <Col>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="statusRad"
                                    id="optionRad1"
                                    checked={addRemarks?.appln_status === "A"}
                                    onChange={() =>
                                        setAddRemarks((prevState) => ({
                                            ...prevState,
                                            appln_status: "A",
                                        }))
                                    }
                                />
                                <label className="form-check-label" htmlFor="optionRad1">
                                    Approve
                                </label>
                            </div>
                        </Col>

                        <Col>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="statusRad"
                                    id="optionRad2"
                                    checked={addRemarks?.appln_status === "N"}
                                    onChange={() =>
                                        setAddRemarks((prevState) => ({
                                            ...prevState,
                                            appln_status: "N",
                                        }))
                                    }
                                />
                                <label className="form-check-label" htmlFor="optionRad2">
                                    Reject
                                </label>
                            </div>
                        </Col>
                    </Row>

                    <div className={`d-flex flex-column gap-3 mt-3`}>
                        <h5 className={`fw-bold mb-0`}>Add remarks</h5>

                        <Row>
                            <Col xs={4}>
                                <label>Application Status Remarks (NYP)</label>
                            </Col>
                            <Col xs={8}>
                                <Select
                                    backspaceRemovesValue={true}
                                    onChange={(value) =>
                                        setAddRemarks((prevState) => ({
                                            ...prevState,
                                            remark: value,
                                        }))
                                    }
                                    options={dataRemark}
                                    value={dataRemark?.find(option => option.value === addRemarks?.remark)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={4}>
                                <label>Special Remarks</label>
                            </Col>
                            <Col xs={8}>
                                <Form.Control
                                    type={"text"}
                                    onChange={(e) =>
                                        setAddRemarks((prevState) => ({
                                            ...prevState,
                                            remarks1: e?.target?.value,
                                        }))
                                    }
                                    value={addRemarks?.remarks1}
                                ></Form.Control>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`gap-2`}>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleAddRemarks}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomModal
                show={showDeleteApplication}
                handleClose={() => setShowDeleteApplication(false)}
                action={handleDeleteApplication}
                title={`Delete record?`}
                description={`The selected record will be removed from the list. Proceed to delete?`}
            />
        </Layout>
    );
}
