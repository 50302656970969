import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {Button, Col, Form, FormCheck, Modal, Row,} from "@govtechsg/sgds-react";
import Select, {components} from "react-select";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import FormulaModal from "@/components/FormulaModal";
import {getListAcadYears, getListExercise,} from "@/apis/preparation/app-timeline/api";
import {getAdmNo, getApplication, patchApplication, postStudentList,} from "@/apis/app-records/verification/api";
import {VerificationStatus,} from "@/apis/app-records/maintenance/status";
import {toast} from "react-toastify";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {Link, useNavigate} from "react-router-dom";
import * as XLSX from 'xlsx';
import {sortDescendingByKey, sortExerciseList} from "@/components/utils/utils";
import moment from "moment-timezone";
import DataTable from 'react-data-table-component';
import {getTimeline,} from "@/apis/preparation/setup-timeline/api";
import {getApplicationListByAcadYrExercise, getScholarship} from "@/apis/app-records/maintenance/api";
import {getRemarkNYP} from "@/apis/preparation/setup-remarks/api";

function AppVerification() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(true);
    const contentRef = useRef(null);

    const [isDisabled, setIsDisabled] = useState(true);
    const [showFormulaModal, setShowFormulaModal] = useState(false);
    const [showModalGroupEdit, setModalGroupEdit] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const [analytic, setAnalytic] = useState({
        include: 0,
        exclude: 0,
        pendingDocument: 0,
        pendingVerif: 0,
    });

    const [academicYearOptions, setAcademicYearOptions] = useState([]);

    const [exerciseOptions, setExerciseOptions] = useState([]);

    const [admNoOptions, setAdmNoOptions] = useState([]);

    const [selectedAcademicYearOption, setSelectedAcademicYearOption] =
        useState(null);

    const [selectedExerciseOption, setSelectedExerciseOption] = useState(null);

    const [selectedAdmNoOption, setSelectedAdmNoOption] = useState(null);

    const [
        selectedVerificationStatusOption,
        setSelectedVerificationStatusOption,
    ] = useState(null);

    const [selectedVerificationStatusValue, setSelectedVerificationStatusValue] = useState("");

    const [selectedOrderBy, setSelectedOrderBy] = useState(null);

    const [isNotEnrolledChecked, setIsNotEnrolledChecked] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const [checkboxRemark, setCheckboxRemark] = useState(false);

    const [checkboxStatusVerify, setCheckboxStatusVerify] = useState(false);

    const [groupRemark, setGroupRemark] = useState("");

    const [groupStatusVerify, setGroupStatusVerify] = useState(null);

    const [selectedOrderDirection, setSelectedOrderDirection] = useState(null);

    const [items, setItems] = useState([]);

    const [totalData, setTotalData] = useState(0)

    const [columnVisibilityOptions, setColumnVisibilityOptions] = useState([
        {
            value: "id_no",
            label: "ID No.",
            visible: true,
            columnEditable: false
        },
        {
            value: "age",
            label: "SNo",
            visible: true,
            columnEditable: false
        },
        {
            value: "adm_no",
            label: "Adm. No.",
            visible: true,
            columnEditable: false,
        },
        {value: "name", label: "Name", visible: true, columnEditable: false},
        {value: "citizenDesc", label: "Citizenship", visible: true, columnEditable: false},
        {value: "pr", label: "PR", visible: true, columnEditable: false},
        {value: "course_code", label: "Course Code", visible: true, columnEditable: false},
        {value: "course", label: "Course", visible: true, columnEditable: false},
        {value: "scholarship", label: "Scholarship", visible: true, columnEditable: false},
        {value: "sponsorDesc", label: "Sponsor Name", visible: true, columnEditable: false},
        {value: "finAssist", label: "Financial Assistance", visible: true, columnEditable: false},
        {value: "scholarship_detail", label: "Scholarship Details", visible: true, columnEditable: false},
        {value: "studStatusDesc", label: "Student Status", visible: true, columnEditable: false},
        {value: "studyStage", label: "Study Stage", visible: true, columnEditable: false},
        {value: "remark", label: "Approval Status Remark (NYP)", visible: true, columnEditable: false},
        {value: "charge_in_court", label: "Charged in Court", visible: true, columnEditable: false},
        {
            value: "ccc_income",
            label: "GHI",
            visible: true,
            columnEditable: false,
        },
        {
            value: "family_members",
            label: "Total Family Members",
            visible: true,
            columnEditable: false,
        },
        {value: "mpci", label: "MPCI", visible: true, columnEditable: false},
        {
            value: "comp_sponsor_tag",
            label: "Company Sponsored",
            visible: true,
            columnEditable: false,
        },
        {
            value: "moe_subsidised",
            label: "MOE SS",
            visible: true,
            columnEditable: false,
        },
        {
            value: "scholar_sponsor_tag",
            label: "Sponsor",
            visible: true,
            columnEditable: false,
        },
        {
            value: "verify_status",
            label: "Verification Status",
            visible: true,
            columnEditable: true,
        },
        {value: "verify_status_by", label: "Verified By", visible: true, columnEditable: true},
        {value: "verify_status_date", label: "Verified Date", visible: true, columnEditable: true},
        {value: "remarks1", label: "Remarks", visible: true, columnEditable: true},
        {value: "last_upd_id", label: "Last Update ID", visible: true, columnEditable: true},
        {value: "updated_at", label: "Updated at", visible: true, columnEditable: true},
    ]);

    const [orderByColumn, setOrderByColumn] = useState("mpci");
    const [orderByDirection, setOrderByDirection] = useState("ASC");
    const [serverSort, setServerSort] = useState(false)

    const columns = [
        {
            value: "id_no",
            label: "ID No.",
            name: "ID No.",
            selector: row => row.student_detail?.idNo ?? "",
            sortable: true
        },
        {
            value: "adm_no", label: "Adm No.", name: "Adm No.", selector: row => row.adm_no, sortable: true,
            cell: row => {
                const url = row.isHomes
                    ? `/app-records/app-homes?academicYearParams=${row.acad_yr}&exerciseParams=${row.exercise}&admNoParams=${row.adm_no}`
                    : `/app-records/app-maintenance?academicYearParams=${row.acad_yr}&exerciseParams=${row.exercise}&admNoParams=${row.adm_no}`;

                return (
                    <Link to={url} target={"_blank"}>
                        {row.adm_no}
                    </Link>
                );

            }
        },
        {value: "name", label: "Name", name: "Name", selector: row => row.student_detail?.name ?? "", sortable: true},
        {
            value: "citizenDesc",
            label: "Citizenship",
            name: "Citizenship",
            selector: row => row.student_detail?.citizenDesc ?? "",
            sortable: true
        },
        {value: "pr", label: "PR", name: "PR", selector: row => row.student_detail?.pr ?? "", sortable: true},
        {
            value: "course_code",
            label: "Course Code",
            name: "Course Code",
            selector: row => row.student_detail?.courseList?.[0]?.courseCode,
            sortable: true
        },
        {
            value: "course",
            label: "Course",
            name: "Course",
            selector: row => row.student_detail?.courseList?.[0]?.courseName,
            sortable: true
        },
        {
            value: "scholarship",
            label: "Scholarship",
            name: "Scholarship",
            selector: row => row.scholar_sponsor_tag,
            sortable: true
        },
        {
            value: "sponsorDesc",
            label: "Sponsor Name",
            name: "Sponsor Name",
            selector: row => row.scholarship_detail?.scholar_sponsor,
            sortable: true
        },
        {
            value: "finAssist",
            label: "Financial Assistance",
            name: "Financial Assistance",
            cell: row => {
                return (
                    <>
                        {row.scholarship_detail?.tutfee_c_tag?.toLowerCase() === "y" ? "Tuition Fee" : row.scholarship_detail?.l_allowance_c_tag?.toLowerCase() === "y" ? "Living Allowance" : "Others"}
                    </>
                );
            },
            sortable: false
        },
        {
            value: "scholarship_detail",
            label: "Scholarship / Sponsorship Detail",
            name: "Scholarship / Sponsorship Detail",
            selector: row => row.scholarship_detail?.others_c_details,
            sortable: true
        },
        {
            value: "studStatusDesc",
            label: "Student Status",
            name: "Student Status",
            selector: row => row.student_detail?.courseList?.[0]?.studStatusDesc,
            sortable: true
        },
        {
            value: "studyStage",
            label: "Study Stage",
            name: "Study Stage",
            selector: row => row.student_detail?.courseList?.[0]?.studyStage,
            sortable: true
        },

        {
            value: "remark",
            label: "Approval Status Remark (NYP)",
            name: "Approval Status Remark (NYP)",
            selector: row => row?.remark_desc,
            sortable: true
        },
        {
            value: "charge_in_court",
            label: "Charged in Court",
            name: "Charged in Court",
            selector: row => row?.charge_in_court,
            sortable: true
        },
        {
            value: "ccc_income",
            label: "GHI",
            name: "GHI",
            selector: row => parseFloat(row.ccc_income ?? 0),
            sortable: true,
        },
        {
            value: "family_members",
            label: "Total Family Members",
            name: "Total Family Members",
            selector: row => parseInt(row.family_members ?? 0),
            sortable: true
        },
        {
            value: "mpci", label: "MPCI", name: "MPCI", selector: row => parseFloat(row.mpci ?? 0), sortable: true,
        },
        {
            value: "comp_sponsor_tag",
            label: "Company Sponsored",
            name: "Company Sponsored",
            selector: row => row.comp_sponsor_tag,
            cell: row => <FormCheck checked={row.comp_sponsor_tag === "Y"} disabled={true}/>,
            sortable: true
        },
        {
            value: "moe_subsidised",
            label: "MOE SS",
            name: "MOE SS",
            selector: row => row.moe_subsidised,
            cell: row => <FormCheck checked={row.moe_subsidised === "Y"} disabled={true}/>,
            sortable: true
        },
        {
            value: "scholar_sponsor_tag",
            label: "Sponsor",
            name: "Sponsor",
            selector: row => row.scholar_sponsor_tag,
            cell: row => <FormCheck checked={row.scholar_sponsor_tag === "Y"} disabled={true}/>,
            sortable: true
        },
        {
            value: "verify_status",
            label: "Verification Status",
            name: "Verification Status",
            selector: row => row.verify_status,
            cell: row => VerificationStatus().find(option => option.value === row?.verify_status)?.label || '',
            sortable: true
        },
        {
            value: "verify_status_by",
            label: "Verified By",
            name: "Verified By",
            selector: row => row.verify_status_by,
            sortable: true
        },
        {
            value: "verify_status_date",
            label: "Verified Date",
            name: "Verified Date",
            selector: row => row.verify_status_date,
            sortable: true
        },
        {value: "remarks1", label: "Remarks", name: "Remarks", selector: row => row.remarks1, sortable: true},
        {
            value: "last_upd_id",
            label: "Last Update ID",
            name: "Last Update ID",
            selector: row => row.last_upd_id,
            sortable: true
        },
        {value: "updated_at", label: "Updated at", name: "Updated at", selector: row => row.updated_at, sortable: true},
        {
            value: "",
            cell: row =>
                <button
                    className={`btn-clear`}
                    onClick={() => {
                        handleSelectItem({
                            selectedRows: [row]
                        });
                        setModalGroupEdit(true)
                    }}
                >
                    <i className={`bi bi-pencil`}></i>
                </button>,
            sortable: true
        }
    ];

    const [selectedColumns, setSelectedColumns] = useState(columns.map(col => col.value))

    const handleColumnChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        if (!selectedValues.includes("")) {
            selectedValues.push("");
        }

        setSelectedColumns(selectedValues);
    };

    const filteredColumns = columns.filter(column => selectedColumns.includes(column.value));

    const [editRowId, setEditRowId] = useState(null);

    const handleEdit = (rowId) => {
        setEditRowId(rowId)
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isNextPage, setIsNextPage] = useState(true);
    const [limit, setLimit] = useState(20)
    const [exportLoading, setExportLoading] = useState(false);

    const handleExport = async () => {
        if (items.length <= 0) {
            toast.error("No Data To Export");
            return false;
        }
        setExportLoading(true);

        let exportDatas = [];

        // Get all data for export
        const academicYear = selectedAcademicYearOption?.value ?? "";
        const exercise = selectedExerciseOption?.value ?? "";
        const admNo = selectedAdmNoOption?.value ?? "";
        let verifStatus = selectedVerificationStatusValue;

        let totalPage = 1;
        let currentPage = 1;

        try {
            do {
                const response = await getApplication(
                    academicYear,
                    exercise,
                    admNo,
                    verifStatus,
                    "",
                    false,
                    currentPage,
                    500,
                    'ASC',
                    true
                );

                const applicationData = response?.data?.data || [];
                totalPage = response?.data?.totalPage || 1;

                const processedData = applicationData.map((item) => ({
                    ...item,
                    name: "-",
                    course_code: "-",
                    course: "-",
                    sponsorDesc: "-",
                    scholarship_detail: "-",
                    remark_desc: "-",
                    pr: "-",
                    citizenDesc: "-",
                    scholarship: "",
                    finAssist: "",
                    studStatusDesc: "",
                    studyStage: "",
                    remark: "",
                    id_no: ""
                }));

                exportDatas = [...exportDatas, ...processedData];
                currentPage++;
            } while (currentPage <= totalPage);

            // Map ADM_NO to data for student processing
            const itemsMap = exportDatas?.reduce((map, item) => {
                map[item.adm_no] = item;
                return map;
            }, {});

            const studentIds = exportDatas.map((item) => item.adm_no);

            if (studentIds.length > 0) {
                const chunkArray = (arr, chunkSize) =>
                    arr.reduce((result, item, index) => {
                        const chunkIndex = Math.floor(index / chunkSize);
                        if (!result[chunkIndex]) result[chunkIndex] = [];
                        result[chunkIndex].push(item);
                        return result;
                    }, []);

                const studentChunks = chunkArray(studentIds, 500);

                for (const chunk of studentChunks) {
                    const students_id = chunk.join(",");
                    const studentResponse = await postStudentList(students_id, false, "id_no", true);

                    const studentResponses = await Promise.all(
                        studentChunks.map(async (chunk) => {
                            const students_id = chunk.join(",");
                            return postStudentList(students_id, false, "id_no", true);
                        })
                    );

                    const studentData = studentResponses.flatMap(response => response?.data?.data ?? []);

                    await Promise.all(studentData.map(async (student) => {
                        const item = itemsMap[student.admNo] || {};
                        if (item) {
                            const [scholarshipResponse, remarkResponse] = await Promise.all([
                                getScholarship(item.acad_yr, item.exercise, item.adm_no),
                                item?.remark ? getRemarkNYP(20, 1, item?.remark) : Promise.resolve(null)
                            ]);

                            item.sponsorDesc = scholarshipResponse?.data?.data?.[0]?.scholar_sponsor ?? "-";
                            item.scholarship_detail = scholarshipResponse?.data?.data?.[0]?.others_c_details ?? "-";
                            item.finAssist = scholarshipResponse?.data?.data?.[0]?.tutfee_c_tag?.toLowerCase() === "y" ? "Tuition Fee" :
                                scholarshipResponse?.data?.data?.[0]?.l_allowance_c_tag?.toLowerCase() === "y" ? "Living Allowance" : "Others";

                            if (remarkResponse) {
                                item.remark_desc = remarkResponse?.data?.data?.[0]?.remark ?? "-";
                            }

                            item.id_no = student.idNo;
                            item.name = student?.name ?? "-";
                            item.course_code = student?.courseList?.[0]?.courseCode ?? "-";
                            item.course = student?.courseList?.[0]?.courseName ?? "-";
                            item.pr = student?.pr ?? "-";
                            item.citizenDesc = student?.citizenDesc ?? "-";
                            item.scholarship = item.scholar_sponsor_tag ?? "-";
                            item.studStatusDesc = student?.courseList?.[0]?.studStatusDesc ?? "-";
                            item.studyStage = student?.courseList?.[0]?.studyStage ?? "-";
                            item.remark = item?.remark_desc ?? "-";
                            item.verify_status = VerificationStatus().find(option => option.value === item?.verify_status)?.label || ''
                        }
                    }));
                }
            }

            // Prepare visible columns and headers
            const visibleColumns = columnVisibilityOptions
                .filter((col) => col.visible && col.value !== "age")
                .map((col) => col.value);

            const headers = columnVisibilityOptions
                .filter((col) => col.visible)
                .map((col) => col.label);

            if (headers.length > 0 && columnVisibilityOptions.find((col) => col.value === "age")?.visible) {
                headers[0] = "SNo";
            }

            // Prepare worksheet data
            const wsData = [
                headers,
                ...exportDatas.map((item, index) => [
                    index + 1,
                    ...visibleColumns.map((col) => item[col] || ""),
                ]),
            ];

            const ws = XLSX.utils.aoa_to_sheet(wsData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, "Application Verification.xlsx");

            setExportLoading(false);
        } catch (error) {
            console.error("Error during export:", error);
            setExportLoading(false);
        }
    };

    const customOption = (props) => (
        <div>
            <components.Option {...props}>
                <input
                    type={"checkbox"}
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );

    const [selectAll, setSelectAll] = useState(false);
    const [groupEdit, setGroupEdit] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const handleSelectItem = (row) => {
        setSelectedRows(row.selectedRows)

        if (row.selectedRows.length === 1) {
            setGroupStatusVerify({
                value: row.selectedRows[0]?.verify_status,
                label: VerificationStatus().find(option => option.value === row.selectedRows[0]?.verify_status)?.label || ''
            })

            setGroupRemark(row.selectedRows[0].remarks1)
        } else {
            setGroupStatusVerify(null)
        }
    };

    const handleSave = (id) => {
        items.map((item) => {
            if (item.id === id) {

                const updatedItem = {
                    id: item.id,
                    acad_yr: item.acad_yr,
                    exercise: item.exercise,
                    adm_no: item.adm_no,
                    gross_income: item.gross_income,
                    dependent: item.dependent,
                    mpci: item.mpci,
                    comp_sponsor_tag: item.comp_sponsor_tag,
                    moe_subsidised: item.moe_subsidised,
                    scholar_sponsor_tag: item.scholar_sponsor_tag,
                    verify_status: item.verify_status ?? "",
                    remarks1: item.remarks1 ?? "",
                    verify_status_by:
                        (item?.verify_status_by == null ||
                            item?.verify_status_by == "") &&
                        item.verify_status !== null
                            ? sessionStorage.getItem("staffId")
                                ? sessionStorage.getItem("staffId")
                                : process.env.REACT_APP_LAST_UPD_ID
                            : item?.verify_status_by,
                    verify_status_date:
                        (item?.verify_status_date == null ||
                            item?.verify_status_date == "") &&
                        item.verify_status !== null
                            ? moment(Date.now())
                                .tz("Asia/Singapore")
                                .format("yyyy-MM-DD HH:mm:ss")
                            : item?.verify_status_date,

                };

                patchApplication(updatedItem).then((resp) => {
                    if (resp?.response?.data?.message) {
                        if (
                            resp?.response?.data?.message?.length > 0 &&
                            Array.isArray(resp?.response?.data?.message)
                        ) {
                            resp?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(resp?.response?.data?.message);
                        }
                    } else if (resp?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        if (resp.status == 200) {
                            toast.success(`Record updated successfully`);
                            handleGetApplication();
                        }
                    }
                });
            }
        });
    };

    const applyGroupEdit = () => {
        if (!checkboxRemark && !checkboxStatusVerify) {
            toast.warning("Please choose the action before proceeding");

            return false;
        }

        if (!groupRemark && !groupStatusVerify) {
            toast.warning("Please complete the form before proceeding");

            return false;
        }

        //submit
        const updatedItem = selectedRows.map((item) => {
            const newItem = {...item};

            if (checkboxRemark) {
                newItem.remarks1 = groupRemark;
            }

            if (checkboxStatusVerify) {
                const beforeChangeItem = {...item}


                newItem.verify_status = groupStatusVerify.value;

                newItem.verify_status_by = (beforeChangeItem?.verify_status_by == null) ? sessionStorage.getItem("staffId") ? sessionStorage.getItem("staffId") : process.env.REACT_APP_LAST_UPD_ID : beforeChangeItem?.verify_status_by;

                newItem.verify_status_date = (beforeChangeItem?.verify_status_date == null) ? moment(Date.now())
                        .tz("Asia/Singapore")
                        .format("yyyy-MM-DD HH:mm:ss")
                    : beforeChangeItem?.verify_status_date;
            }


            return {
                acad_yr: newItem?.acad_yr,
                exercise: newItem?.exercise,
                adm_no: newItem?.adm_no,
                verify_status: newItem?.verify_status,
                verify_status_by: newItem?.verify_status_by,
                verify_status_date: newItem?.verify_status_date,
                remarks1: newItem?.remarks1,
                ack_no: newItem?.ack_no,
            };
        });

        patchApplication(updatedItem).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success(`Record updated successfully`);
                    handleGetApplication();
                    setModalGroupEdit(false);
                    setGroupEdit([]);
                    setCheckboxRemark(false);
                    setCheckboxStatusVerify(false);
                    setGroupRemark("");
                    setGroupStatusVerify(null);
                }
            }
        });
    };

    const updateGroupEditForm = (selectedItems) => {
        if (selectedItems.length === 0) {
            setGroupEdit([]);
            return;
        }

        setGroupEdit(
            selectedItems.map((item) => ({
                id: item.id,
                acad_yr: item.acad_yr,
                exercise: item.exercise,
                adm_no: item.adm_no,
            }))
        );
    };

    const toggleColumnVisibility = (key) => {
        setColumnVisibilityOptions(
            columnVisibilityOptions.map((column) =>
                column.value === key ? {...column, visible: true} : column
            )
        );
    };

    const getAcademicYearOptions = () => {
        getListAcadYears().then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    const acadYearsList = resp?.data?.data?.map((option) => ({
                        label: option.acad_yr,
                        value: option.acad_yr,
                    }))

                    const sortedAcademyYears = sortDescendingByKey(acadYearsList, "value");

                    setAcademicYearOptions(
                        sortedAcademyYears
                    );
                }
            }
        });
    };

    const getExerciseOptions = (academicYear) => {
        getListExercise(academicYear).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data?.length > 0) {
                        setExerciseOptions(
                            sortExerciseList(resp?.data?.data).map((option) => ({
                                label: option.exercise,
                                value: option.exercise,
                            }))
                        );
                    }
                }
            }
        });
    };

    const getAdmNoOptions = (year, exercise) => {
        getAdmNo(year, exercise).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setAdmNoOptions(
                        resp?.data?.data?.map((option) => ({
                            label: option.adm_no,
                            value: option.adm_no,
                        }))
                    );
                }
            }
        });
    };

    const handleChangeAcademicYearOption = (academicYear) => {
        if (academicYear !== null) {
            getExerciseOptions(academicYear?.value);
        } else {
            setExerciseOptions([]);
        }
        setSelectedAcademicYearOption(academicYear);
        setSelectedExerciseOption(null);
    };

    const handleChangeExerciseOption = (exercise) => {
        if (exercise !== null && selectedAcademicYearOption) {
            getAdmNoOptions(selectedAcademicYearOption?.value, exercise?.value);
        } else {
            setAdmNoOptions([]);
        }
        setSelectedExerciseOption(exercise);
        setSelectedAdmNoOption(null);
    };

    const handleChangeAdmNoOption = (admNo) => {
        setSelectedAdmNoOption(admNo);
    };

    const handleChangeVerificationStatusOption = (verificationStatus) => {
        if (verificationStatus === null) {
            setSelectedVerificationStatusValue("")
        } else {
            setSelectedVerificationStatusValue(verificationStatus?.value)
        }
        setSelectedVerificationStatusOption(verificationStatus);
    };

    const handleChangeOrderBy = (selectedOption) => {
        setSelectedOrderBy(selectedOption);
    };
    const handleChangeOrderDirection = (selectedOption) => {
        setSelectedOrderDirection(selectedOption);
    };

    const handleGetApplication = () => {
        const academicYear = selectedAcademicYearOption?.value ?? "";
        const exercise = selectedExerciseOption?.value ?? "";
        const admNo = selectedAdmNoOption?.value ?? "";
        let verifStatus = selectedVerificationStatusValue;
        // const orderField = selectedOrderBy?.value ?? "";
        // const orderDirection = selectedOrderDirection?.value ?? "";
        const orderField = orderByColumn
        const orderDirection = orderByDirection
        const isCheckedNoEnrolled = isNotEnrolledChecked;

        getApplication(
            academicYear,
            exercise,
            admNo,
            verifStatus,
            orderField,
            isCheckedNoEnrolled,
            currentPage,
            limit,
            orderDirection,
            true
        ).then(async (response) => {
            setCurrentPage(response?.data?.currentPage);
            setTotalPages(response?.data?.totalPage);
            setIsNextPage(response?.data?.nextPage);
            setTotalData(response?.data?.totalData)

            const modifiedItems = await Promise.all(
                response?.data?.data?.map(async (item) => {
                    let isHomes = false;

                    // Await the getTimeline call
                    const timelineResponse = await getTimeline(item?.acad_yr, item?.exercise);
                    const timelineData = timelineResponse?.data?.data?.[0];
                    if (timelineData?.homes_tag === 'Y') {
                        isHomes = true;
                    }

                    return {
                        ...item,
                        checked: false,
                        editable: false,
                        name: "",
                        course: "",
                        isHomes: isHomes,
                    };
                })
            );

            setItems(modifiedItems);

            const itemsMap = modifiedItems?.reduce((map, item) => {
                map[item.adm_no] = item;
                return map;
            }, {});

            const students_id = response?.data?.data
                ?.map((application) => application.adm_no)
                .join(",");


            if (students_id) {
                postStudentList(students_id, isNotEnrolledChecked).then(
                    (response2) => {
                        response2?.data?.data.forEach((student) => {
                            const item = itemsMap[student.admNo];
                            if (item) {
                                getScholarship(item.acad_yr, item.exercise, item.adm_no).then((response) => {
                                    setItems((prevItems) =>
                                        prevItems.map((prevItem) => {
                                            if (prevItem.adm_no === item.adm_no) {
                                                return {
                                                    ...prevItem,
                                                    scholarship_detail: response?.data?.data?.[0] || []
                                                };
                                            }
                                            return prevItem;
                                        })
                                    );
                                })

                                if (item?.remark) {
                                    getRemarkNYP(20, 1, item?.remark).then((response) => {
                                        setItems((prevItems) =>
                                            prevItems.map((prevItem) => {
                                                if (prevItem.adm_no === item.adm_no) {
                                                    return {
                                                        ...prevItem,
                                                        remark_desc: response?.data?.data?.[0]?.remark || ""
                                                    };
                                                }
                                                return prevItem;
                                            })
                                        );
                                    })
                                }

                                setItems((prevItems) =>
                                    prevItems.map((prevItem) => {
                                        if (prevItem.adm_no === item.adm_no) {
                                            return {
                                                ...prevItem,
                                                student_detail: student
                                            };
                                        }
                                        return prevItem;
                                    })
                                );
                            }
                        });
                    }
                );
            }

            setAnalytic({
                include: selectedVerificationStatusValue === "I" || selectedVerificationStatusValue === "" ? response?.data?.analytic?.include : "0",
                exclude: selectedVerificationStatusValue === "E" || selectedVerificationStatusValue === "" ? response?.data?.analytic?.exclude : "0",
                pendingDocument: selectedVerificationStatusValue === "P" || selectedVerificationStatusValue === "" ? response?.data?.analytic?.pendingDocument : "0",
                pendingVerif: selectedVerificationStatusValue === null || selectedVerificationStatusValue === "" ? response?.data?.analytic?.pendingVerif : "0",
            })
        });
    };

    const retrieveApplicationData = (e) => {
        e.preventDefault();
        handleGetApplication();
    };

    const handleSelectedVisibilityOptions = (selectedOptions) => {
        setSelectedOptions(selectedOptions);

        const updatedOptions = columnVisibilityOptions.map((option) => ({
            ...option,
            visible: selectedOptions.some(
                (selected) => selected.value === option.value
            ),
        }));

        setColumnVisibilityOptions(updatedOptions);
    };

    useEffect(() => {
        getAcademicYearOptions();
        setSelectedOptions(
            columnVisibilityOptions.map((option) => ({
                value: option.value,
                label: option.label,
            }))
        );
    }, []);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleSort = async (column, sortDirection) => {
        if (column.value === "adm_no" || column.value === "ccc_income" || column.value === "mpci" || column.value === "verify_status") {
            setServerSort(true)
            setOrderByColumn(column.value);
            setOrderByDirection(sortDirection.toUpperCase());
        } else {
            setServerSort(false)
        }
    }

    const handleGetListAdminNo = async (search = "") => {
        try {
            const response = await getApplicationListByAcadYrExercise({
                acad_yr: selectedAcademicYearOption.value,
                exercise: selectedExerciseOption.value,
                adm_no: search,
                limit: 20
            });

            setAdmNoOptions(
                response?.data?.data?.map((item) => ({
                    label: item?.adm_no,
                    value: item?.adm_no,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const refTimeoutSearch = useRef(null);

    const handleSearch = (searchFunction = null, value = null) => {
        clearTimeout(refTimeoutSearch.current);

        refTimeoutSearch.current = setTimeout(() => {
            searchFunction(value);
        }, 500);
    };

    useEffect(() => {
        handleGetApplication()
    }, [currentPage, orderByColumn, orderByDirection])

    return (
        <Layout currentNav={"app-records"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Administer Application Records</Breadcrumb.Item>
                <Breadcrumb.Item active>Application Verification</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Application Verification</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <div className="row">
                        <div className="col">
                            <h3>Data Retrieval</h3>
                        </div>
                    </div>

                    <Form
                        ref={contentRef}
                        className={` d-flex flex-column gap-3 collapse-animation`}
                        style={{
                            overflow: isOpen ? "unset" : "hidden",
                            maxHeight: isOpen
                                ? `${
                                    contentRef?.current?.scrollHeight == null
                                        ? "100%"
                                        : contentRef?.current?.scrollHeight
                                }px`
                                : "0px",
                        }}
                        onSubmit={retrieveApplicationData}
                    >
                        <Row>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Academic Year</Form.Label>
                                    <Select
                                        isClearable={true}
                                        options={academicYearOptions}
                                        value={selectedAcademicYearOption}
                                        onChange={handleChangeAcademicYearOption}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Exercise</Form.Label>
                                    <Select
                                        isClearable={true}
                                        options={exerciseOptions}
                                        value={selectedExerciseOption}
                                        onChange={handleChangeExerciseOption}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Adm. No.</Form.Label>
                                    <Select
                                        isClearable={true}
                                        options={admNoOptions}
                                        value={selectedAdmNoOption}
                                        onChange={handleChangeAdmNoOption}
                                        onInputChange={(value) =>
                                            handleSearch(handleGetListAdminNo, value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {/*<Col xs={8} className="">*/}
                            {/*    <Form.Group>*/}
                            {/*        <Form.Label>Name</Form.Label>*/}
                            {/*        <Form.Control type={`text`} disabled={false}></Form.Control>*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Verification Status</Form.Label>
                                    <Select
                                        isClearable={true}
                                        options={VerificationStatus()}
                                        value={selectedVerificationStatusOption}
                                        onChange={handleChangeVerificationStatusOption}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {/*<Col xs={4}>*/}
                            {/*    <Form.Group>*/}
                            {/*        <Form.Label>Order By</Form.Label>*/}
                            {/*        <Select*/}
                            {/*            isClearable={true}*/}
                            {/*            options={appVerificationOrderBy()}*/}
                            {/*            value={selectedOrderBy}*/}
                            {/*            onChange={handleChangeOrderBy}*/}
                            {/*        />*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*<Col xs={4}>*/}
                            {/*    <Form.Group>*/}
                            {/*        <Form.Label>Order Direction</Form.Label>*/}
                            {/*        <Select*/}
                            {/*            isClearable={true}*/}
                            {/*            options={[{label: "ASC", value: "ASC"}, {label: "DESC", value: "DESC"}]}*/}
                            {/*            value={selectedOrderDirection}*/}
                            {/*            onChange={handleChangeOrderDirection}*/}
                            {/*        />*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*<Col xs={3} className="d-flex align-items-end">*/}
                            {/*    {" "}*/}
                            {/*    <Form.Group className="">*/}
                            {/*        <FormCheck*/}
                            {/*            id="isNotEnrolled"*/}
                            {/*            label="Is Not Enrolled"*/}
                            {/*            name="is_not_enrolled "*/}
                            {/*            type="checkbox"*/}
                            {/*            onChange={(e) =>*/}
                            {/*                setIsNotEnrolledChecked(e.target.checked)*/}
                            {/*            }*/}
                            {/*        />*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <Button type="submit" className="btn btn-primary">
                                    Retrieve
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    <button
                        onClick={toggleCollapse}
                        className="btn-clear text-blue ps-0 mt-3"
                    >
                        {isOpen ? "Hide " : "Expand "}{" "}
                        {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                    </button>
                </div>

                <div className="p-3 bg-white shadow-sm border rounded border-1">
                    <Row>
                        <Col xs={12} md={4} className="mt-3">
                            <Form.Group>
                                <Form.Label>Application Included</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={isDisabled}
                                    value={
                                        analytic?.include
                                    }
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="mt-3">
                            <Form.Group>
                                <Form.Label>Application Excluded</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={isDisabled}
                                    value={
                                        analytic?.exclude
                                    }
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="mt-3">
                            <Form.Group>
                                <Form.Label>Application Pending Document</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={isDisabled}
                                    value={
                                        analytic?.pendingDocument
                                    }
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="mt-3">
                            <Form.Group>
                                <Form.Label>Application Pending Verification</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={isDisabled}
                                    value={
                                        analytic?.pendingVerif
                                    }
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="mt-3">
                            <Form.Group>
                                <Form.Label>Total Applicant Retrieved</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={isDisabled}
                                    value={
                                        totalData ?? ""
                                    }
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4 p-3 bg-white shadow-sm border rounded border-1">
                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Columns visibility</Form.Label>
                                <Select
                                    isMulti
                                    options={columns.filter(column => column.value)}
                                    onChange={handleColumnChange}
                                    defaultValue={columns.filter(column => column.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end align-items-end">
                            <button
                                type="button"
                                className="btn btn-outline-primary me-3"
                                onClick={() => {
                                    if (selectedRows.length > 0) {
                                        setModalGroupEdit(true)
                                    } else {
                                        toast.warning("Please select at least 1 record")
                                    }
                                }}
                            >
                                Group Edit
                            </button>
                            <button type="button" className="btn btn-primary" onClick={handleExport}
                                    disabled={exportLoading}>
                                {exportLoading ? "Loading . . ." : "Export"}
                            </button>
                        </Col>

                        <Col xs={12}>
                            {/*Data Table*/}
                            <DataTable
                                columns={filteredColumns}
                                data={items}
                                onSelectedRowsChange={handleSelectItem}
                                selectableRows
                                pagination
                                paginationTotalRows={totalData}
                                paginationRowsPerPageOptions={[20]}
                                paginationPerPage={limit}
                                paginationServer
                                onChangePage={page => setCurrentPage(page)}
                                sortServer={serverSort}
                                onSort={handleSort}
                            />
                        </Col>
                    </Row>
                </div>
            </sgds-content-body>
            <FormulaModal
                show={showFormulaModal}
                handleClose={() => setShowFormulaModal(!showFormulaModal)}
            />
            <Modal
                size={"lg"}
                show={showModalGroupEdit}
                onHide={() => setModalGroupEdit(!showModalGroupEdit)}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="selected-highlight p-3">
                        Selected {selectedRows.map((indv, index) => (
                        <React.Fragment key={index}>
                            {indv.name + " " + indv.adm_no || indv.adm_no}
                            {index < selectedRows.length - 1 && ", "}
                            <br/>
                        </React.Fragment>
                    ))}
                    </div>
                    <div>
                        <Row>
                            <Col xs={12} className="mt-4">
                                <h6>Choose Action</h6>
                            </Col>
                            <Col xs={12}>
                                <FormCheck
                                    id="verification_status"
                                    label="Verification Status"
                                    name="verification_status "
                                    type="checkbox"
                                    checked={checkboxStatusVerify}
                                    onChange={() =>
                                        setCheckboxStatusVerify(!checkboxStatusVerify)
                                    }
                                />
                                <FormCheck
                                    id="remarks"
                                    label="Remarks"
                                    name="remarks "
                                    type="checkbox"
                                    checked={checkboxRemark}
                                    onChange={() => setCheckboxRemark(!checkboxRemark)}
                                />
                            </Col>
                            <Col xs={12} className="mt-4">
                                <h6>Add remarks</h6>
                            </Col>
                            <Col xs={12}>
                                <Row className="mt-3">
                                    <Col xs={3}>Verification Status</Col>
                                    <Col xs={9}>
                                        <Select
                                            options={VerificationStatus()}
                                            isDisabled={!checkboxStatusVerify}
                                            value={groupStatusVerify}
                                            onChange={(option) => setGroupStatusVerify(option)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={3}>Remarks</Col>
                                    <Col xs={9}>
                                        <Form.Control
                                            type="text"
                                            value={groupRemark}
                                            disabled={!checkboxRemark}
                                            onChange={(e) => setGroupRemark(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`gap-2`}>
                    <Button
                        variant="outline-dark"
                        onClick={() => setModalGroupEdit(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            applyGroupEdit();
                        }}
                    >
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    );
}

export default AppVerification;
