import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {
    Button,
    Col,
    Dropdown,
    Form,
    FormCheck,
    FormControl,
    FormControlGroup,
    Modal,
    Row,
} from "@govtechsg/sgds-react";
import Select from "react-select";
import {toast} from "react-toastify";
import {
    deleteRole,
    getRolesList,
    getRoleStaffList,
    getStaffList,
    updateRoleMultipleEmployee,
    updateRoleSingleEmployee
} from "@/apis/utilities/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import CustomModal from "@/components/CustomModal";
import SortableTable from "@/components/SortableTable";
import * as XLSX from "xlsx";

const options = [
    {value: "FAAS_ADM", label: "FAAS_ADM"},
    {value: "FAAS_VIEW", label: "FAAS_VIEW"},
    {value: "FAAS_TEMP", label: "FAAS_TEMP"},
    {value: "FAAS_TEMPA", label: "FAAS_TEMPA"},
];

function SystemAdministration() {
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [sysRole, setSysRole] = useState("");
    const [sysRolesList, setSysRolesList] = useState([]);
    const [searchValue, setSearchValue] = useState("")

    const [columnVisibilityOptions, setColumnVisibilityOptions] = useState([
        {
            key: "check",
            value: "check",
            label: "",
            visible: true,
            editable: false,
        },
        {
            key: "staffId",
            value: "staffId",
            label: "Staff Id",
            visible: true,
            editable: false,
        },
        {
            key: "preferredName",
            value: "preferredName",
            label: "Name",
            visible: true,
            editable: false,
        },
        {
            key: "campusCode",
            value: "campusCode",
            label: "Campus Code",
            visible: true,
            editable: false,
        },
        {
            key: "schoolDiv",
            value: "schoolDiv",
            label: "School Division",
            visible: true,
            editable: false,
        },
        {
            key: "role",
            value: "role",
            label: "System Role",
            visible: true,
            editable: true,
        },
        {
            key: "action",
            value: "action",
            label: "",
            visible: true,
            editable: false,
        },
    ]);

    const [items, setItems] = useState([]);

    const [staffList, setStaffList] = useState([])

    const [showAddModal, setShowAddModal] = useState(false)
    const [selectedStaffDetail, setSelectedStaffDetail] = useState({})

    const [selectAll, setSelectAll] = useState(false);
    const [groupEdit, setGroupEdit] = useState({name: "", age: ""});
    const [showModalChangeRole, setShowModalChangeRole] = useState(false);

    const [currentPage, setCurrentPage] = useState(1); // State to track the current page
    const [totalRecords, setTotalRecords] = useState(0); // State to track total number of pages
    const [loading, setLoading] = useState(false); // State to manage loading state
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedStaffId, setSelectedStaffId] = useState("");
    const checkedRef = useRef();

    const itemsPerPage = 100; // Number of items per page
    // Function to calculate total pages based on total records
    const totalPages = Math.ceil(totalRecords / itemsPerPage);

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setItems(items.map((item) => ({...item, checked: newSelectAll})));
        updateGroupEditForm(newSelectAll ? items : []);
    };
    const handleMultipleChangeRole = () => {
        setShowModalChangeRole(true);
    };

    const handleSelectItem = (id, role, e) => {
        if (e.target.checked) {
            setSelectedEmployee([...selectedEmployee, {staffId: id, role: role}]);
        } else {
            setSelectedEmployee(
                selectedEmployee.filter((a) => a.staffId !== id && a.role !== role)
            );
        }
        const newItems = items.map((item) => {
            if (item.staffId === id && item.role === role) {
                return {...item, checked: !item.checked};
            }
            return item;
        });
        setItems(newItems);
        setSelectAll(newItems.every((item) => item.checked));
        updateGroupEditForm(newItems.filter((item) => item.checked));
    };

    const handleChangeRole = (value) => {
        setSysRole(value);
    };

    const handleSingleUpdate = async (staffId, role) => {
        try {
            await updateRoleSingleEmployee(
                staffId,
                sysRole.applnSys,
                // sessionStorage.getItem("role")
                //     ? sessionStorage.getItem("role")
                //     : "FAASSU",
                sessionStorage.getItem("staffId")
                    ? sessionStorage.getItem("staffId")
                    : process.env.REACT_APP_LAST_UPD_ID,
                sessionStorage.getItem("staffId")
                    ? sessionStorage.getItem("staffId")
                    : process.env.REACT_APP_LAST_UPD_ID,
                sysRole.value,
                role
            )
                .then((res) => {
                    if (res?.response?.data?.message) {
                        if (
                            res?.response?.data?.message?.length > 0 &&
                            Array.isArray(res?.response?.data?.message)
                        ) {
                            res?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(res?.response?.data?.message);
                        }
                    } else if (res?.response?.status === 404) {
                        toast.error("Data not found");
                    } else {
                        handleEdit(staffId, role);
                        handleGetStaffList()
                        toast.success("System role has been updated successfully");
                    }
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    };

    const handleMultipleUpdate = async () => {
        try {

            let data;
            (data = selectedEmployee.map((emp) => {
                return {
                    staff_id: emp.staffId,
                    application_sys: sysRole.applnSys,
                    assigned_by: sessionStorage.getItem("staffId")
                        ? sessionStorage.getItem("staffId")
                        : process.env.REACT_APP_LAST_UPD_ID,
                    // assigned_by: sessionStorage.getItem("role")
                    //     ? sessionStorage.getItem("role")
                    //     : "FAASSU",
                    last_upd_id: sessionStorage.getItem("staffId")
                        ? sessionStorage.getItem("staffId")
                        : process.env.REACT_APP_LAST_UPD_ID,
                    role: sysRole.value,
                    org_role: emp.role,
                    action: "U",
                };
            })),
                await updateRoleMultipleEmployee(data)
                    .then((res) => {
                        if (res?.response?.data?.message) {
                            if (
                                res?.response?.data?.message?.length > 0 &&
                                Array.isArray(res?.response?.data?.message)
                            ) {
                                res?.response?.data?.message?.map((contentErr) => {
                                    toast.error(contentErr?.msg);
                                });
                            } else {
                                toast.error(res?.response?.data?.message);
                            }
                        } else if (res?.response?.status === 404) {
                            toast.error("Data not found");
                        } else {
                            handleGetStaffList()
                            toast.success("System role has been updated successfully");
                        }
                    })
                    .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    };

    const handleEdit = (id, role) => {
        const labels = sysRolesList.find(content => {
            if (content?.value == role) {
                return content?.label
            } else {
                return ""
            }
        });

        setSysRole({
            label: labels?.label,
            value: role,
        })
        setItems(
            items.map((item) => {
                if (item.staffId === id && item?.role === role) {
                    return {...item, editable: !item.editable};
                }
                return item;
            })
        );
    };

    const handleCancelEdit = (id, role) => {
        setItems(
            items.map((item) => {
                if (item.staffId === id && item?.role === role) {
                    return {...item, editable: !item.editable};
                }
                return item;
            })
        );
    };

    const handleGetListRoles = () => {
        try {
            getRolesList().then((response) => {
                setSysRolesList(
                    response.data?.data.map((item) => ({
                        value: item?.roleId,
                        label: item?.roleDesc,
                        applnSys: item?.applnSys,
                    }))
                );
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleGetListStaff = (search = "") => {
        getStaffList(search).then((response) => {
            const options = response?.data?.data?.map((item) => ({
                application_sys: item?.applicationSys,
                value: item?.staffId,
                label: item?.staffId + " - " + item?.preferredName,
            }));
            setStaffList(options);
        });
    };

    const handleAddNewStaff = async () => {
        try {
            await updateRoleSingleEmployee(
                selectedStaffId,
                "FAAS",
                sessionStorage.getItem("staffId")
                    ? sessionStorage.getItem("staffId")
                    : process.env.REACT_APP_LAST_UPD_ID,
                // sessionStorage.getItem("role")
                //     ? sessionStorage.getItem("role")
                //     : "FAASSU",
                sessionStorage.getItem("staffId")
                    ? sessionStorage.getItem("staffId")
                    : process.env.REACT_APP_LAST_UPD_ID,
                sysRole.value,
                ""
            )
                .then((res) => {
                    if (res?.response?.data?.message) {
                        if (
                            res?.response?.data?.message?.length > 0 &&
                            Array.isArray(res?.response?.data?.message)
                        ) {
                            res?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(res?.response?.data?.message);
                        }
                    } else if (res?.response?.status === 404) {
                        toast.error("Data not found");
                    } else {
                        handleGetStaffList()
                        setShowAddModal(false)
                        toast.success("System role has been added successfully");
                    }
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }

    }

    const handleExport = async () => {
        if (items) {
            const worksheet = XLSX.utils.json_to_sheet(items);
            const workbook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

            XLSX.writeFile(workbook, `SystemAdministration.xlsx`);
        }
    }

    useEffect(() => {
        handleGetListRoles();
    }, []);

    const handleActionDelete = () => {
        handleDelete(selectedStaffId);
    };

    const handleDelete = async (id) => {
        await deleteRole(
            id,
            selectedStaffDetail?.applicationSys,
            sessionStorage.getItem("staffId")
                ? sessionStorage.getItem("staffId")
                : process.env.REACT_APP_LAST_UPD_ID,
            // sessionStorage.getItem("role")
            //     ? sessionStorage.getItem("role")
            //     : "FAASSU",
            sessionStorage.getItem("staffId")
                ? sessionStorage.getItem("staffId")
                : process.env.REACT_APP_LAST_UPD_ID,
            selectedStaffDetail?.role)
            .then((res) => {
                toast.success("Item has been deleted successfully");
                setShowDeleteModal(false);
                setSelectedStaffDetail({})
                setSelectedStaffId("")
                handleGetStaffList()
            })
            .catch((err) => {
                console.log(err);
                toast.error("Failed to delete item");
                setShowDeleteModal(false);
            });
    };

    const applyGroupEdit = () => {
        setItems(
            items.map((item) => {
                if (item.checked) {
                    return {...item, ...groupEdit};
                }
                return item;
            })
        );
        setGroupEdit({name: "", age: "", role: ""});
    };

    const updateGroupEditForm = (selectedItems) => {
        if (selectedItems.length === 0) {
            setGroupEdit({name: "", age: "", role: ""});
            return;
        }
        const name = selectedItems.map((item) => item.name).join(", ");
        const age = selectedItems.map((item) => item.age).join(", ");
        const role = selectedItems.map((item) => item.role).join(", ");
        setGroupEdit({name, age, role});
    };

    const handleSearch = (e) => {
        setSearchValue(e.target.value)
        getRoleStaffList(e.target.value)
            .then((resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        setItems(
                            resp?.data?.data.map((item) => ({
                                ...item,
                                checked: false,
                                editable: false,
                            }))
                        );
                    }
                }
            })
            .catch((err) => {
                console.log("error sys admin");
                console.log(err);
            });
    };

    const handleGetStaffList = () => {
        getRoleStaffList(searchValue, currentPage, itemsPerPage)
            .then((resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        setTotalRecords(resp?.data?.recordCnt);
                        setItems(
                            resp?.data?.data.map((item) => ({
                                ...item,
                                checked: false,
                                editable: false,
                            }))
                        );
                    }
                }
            })
            .catch((err) => {
                console.log("error sys admin");
                console.log(err);
            });
    }

    useEffect(() => {
        handleGetStaffList()
        handleGetListStaff();
    }, []);

    // Handler to go to the next page
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handler to go to the previous page
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const renderActionButtons = (row, rowIndex) => (
        <div className={`d-flex gap-1`}>
            {row.editable ? (
                <React.Fragment>
                    <button
                        className={`btn-clear text-green-500`}
                        onClick={() => handleSingleUpdate(row.staffId, row.role)}
                    >
                        <i className={`bi bi-check-lg`}></i> Save
                    </button>
                    <button
                        className={`btn-clear text-red-500`}
                        onClick={() => handleCancelEdit(row.staffId, row.role)}
                    >
                        <i className={`bi bi-x-circle`}></i>
                        {` `}
                        Cancel
                    </button>
                </React.Fragment>
            ) : (
                <button
                    className={`btn-clear`}
                    onClick={() => {
                        handleEdit(row.staffId, row.role)
                    }}
                >
                    <i className={`bi bi-pencil`}></i>
                </button>
            )}
            <button
                className={`btn-clear`}
                onClick={() => {
                    setShowDeleteModal(true);
                    setSelectedStaffDetail(row)
                    setSelectedStaffId(row.staffId);
                }}
            >
                <i className={`bi bi-trash`}></i>
            </button>
        </div>
    );

    const renderCellContent = (row, rowIndex, key) => {
        if (row?.editable) {
            if (key === "role") {
                return (
                    <Select
                        menuPortalTarget={document.body}
                        options={sysRolesList}
                        onChange={(value) => handleChangeRole(value)}
                        value={sysRole}
                    />
                );
            } else {
                return row[key];
            }
        } else {
            return row[key];
        }
    };

    const renderCheckButtons = (row, rowIndex) => (
        <FormCheck
            id="table_no"
            label=""
            name="table_no "
            type="checkbox"
            value={row.staffId}
            checked={row.checked}
            ref={checkedRef}
            onChange={(e) => handleSelectItem(row.staffId, row.role, e)}
        />
    );

    const fetchData = async (page) => {
        setLoading(true);
        try {
            getRoleStaffList("", page, itemsPerPage)
                .then((resp) => {
                    if (resp?.response?.data?.message) {
                        if (
                            resp?.response?.data?.message?.length > 0 &&
                            Array.isArray(resp?.response?.data?.message)
                        ) {
                            resp?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(resp?.response?.data?.message);
                        }
                    } else if (resp?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        if (resp.status == 200) {
                            console.log(resp);
                            setTotalRecords(resp?.data?.recordCnt);
                            setItems(
                                resp?.data?.data.map((item) => ({
                                    ...item,
                                    checked: false,
                                    editable: false,
                                }))
                            );
                        }
                    }
                })
                .catch((err) => {
                    console.log("error sys admin");
                    console.log(err);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setLoading(false);
    };

    // Effect to fetch data when the component mounts or when `currentPage` changes
    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    return (
        <Layout currentNav={"utilities"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Utilities</Breadcrumb.Item>
                <Breadcrumb.Item active>System Administration</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>System Administration</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <Row>
                        <Col xs={6}>
                            <div className="d-flex gap-3">
                                <Button onClick={() => {
                                    setShowAddModal(true)
                                }}>Add new Role</Button>

                                <Button onClick={handleExport}>Export</Button>
                            </div>
                            <Form.Group className="mb-3" controlId="formControlWithIcon">
                                <Form.Label>Find Employee</Form.Label>
                                <FormControlGroup icon={<i className="bi bi-search"></i>}>
                                    <FormControl
                                        onChange={handleSearch}
                                        placeholder={"Search by employee number or name"}
                                    />
                                </FormControlGroup>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end align-items-end">
                            <Dropdown>
                                <Dropdown.Toggle className={`btn btn-outline-dark`}>
                                    <i className="bi bi-three-dots-vertical"></i>
                                    Change Role
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleMultipleChangeRole}>
                                        Change Role
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>

                    {/*<SortableTable headers={columnVisibilityOptions} data={items} renderActionButtons={renderActionButtons}*/}
                    {/*               renderCellContent={renderCellContent} renderCheckButtons={renderCheckButtons} setData={setItems} selectAll={selectAll} handleSelectAll={handleSelectAll} />*/}

                    {loading ? (
                        "Loading..."
                    ) : (
                        <SortableTable
                            headers={columnVisibilityOptions}
                            data={items}
                            renderActionButtons={renderActionButtons}
                            renderCellContent={renderCellContent}
                            renderCheckButtons={renderCheckButtons}
                            setData={setItems}
                            selectAll={selectAll}
                            handleSelectAll={handleSelectAll}
                        />
                    )}
                    {/*<Table responsive>*/}
                    {/*  <Table.Header>*/}
                    {/*    <Table.Row>*/}
                    {/*      <Table.HeaderCell>*/}
                    {/*        <FormCheck*/}
                    {/*          id="table_no"*/}
                    {/*          label=""*/}
                    {/*          name="table_no "*/}
                    {/*          type="checkbox"*/}
                    {/*          checked={selectAll}*/}
                    {/*          onChange={handleSelectAll}*/}
                    {/*        />*/}
                    {/*      </Table.HeaderCell>*/}
                    {/*      {columnVisibilityOptions.map(*/}
                    {/*        (column) =>*/}
                    {/*          column.visible && (*/}
                    {/*            <Table.HeaderCell key={column.value}>*/}
                    {/*              {column.label}*/}
                    {/*            </Table.HeaderCell>*/}
                    {/*          )*/}
                    {/*      )}*/}
                    {/*      <Table.HeaderCell></Table.HeaderCell>*/}
                    {/*    </Table.Row>*/}
                    {/*  </Table.Header>*/}
                    {/*  <Table.Body>*/}
                    {/*    {items?.map((item, index) => (*/}
                    {/*      <Table.Row key={index}>*/}
                    {/*        <Table.DataCell>*/}
                    {/*          <FormCheck*/}
                    {/*            id="table_no"*/}
                    {/*            label=""*/}
                    {/*            name="table_no "*/}
                    {/*            type="checkbox"*/}
                    {/*            value={item.role}*/}
                    {/*            checked={item.checked}*/}
                    {/*            ref={checkedRef}*/}
                    {/*            onChange={(e) => handleSelectItem(item.role, e)}*/}
                    {/*          />*/}
                    {/*        </Table.DataCell>*/}
                    {/*        {columnVisibilityOptions.map(*/}
                    {/*          (column, i) =>*/}
                    {/*            column.visible && (*/}
                    {/*              <td key={column.value}>*/}
                    {/*                {item.editable ? (*/}
                    {/*                  i == 5 ? (*/}
                    {/*                    <Select*/}
                    {/*                      options={sysRolesList}*/}
                    {/*                      onChange={(value) => handleChangeRole(value)}*/}
                    {/*                      value={sysRole}*/}
                    {/*                    />*/}
                    {/*                  ) : (*/}
                    {/*                    item[column.value]*/}
                    {/*                  )*/}
                    {/*                ) : (*/}
                    {/*                  item[column.value]*/}
                    {/*                )}*/}
                    {/*              </td>*/}
                    {/*            )*/}
                    {/*        )}*/}
                    {/*        <Table.DataCell>*/}
                    {/*          {item.editable ? (*/}
                    {/*            <button*/}
                    {/*              className={`btn-clear text-green-500`}*/}
                    {/*              onClick={() =>*/}
                    {/*                handleSingleUpdate(item.staffId, item.role)*/}
                    {/*              }*/}
                    {/*            >*/}
                    {/*              <i className={`bi bi-check-lg`}></i> Save*/}
                    {/*            </button>*/}
                    {/*          ) : (*/}
                    {/*            <button*/}
                    {/*              className={`btn-clear`}*/}
                    {/*              onClick={() => handleEdit(item.role)}*/}
                    {/*            >*/}
                    {/*              <i className={`bi bi-pencil`}></i>*/}
                    {/*            </button>*/}
                    {/*          )}*/}
                    {/*          <button*/}
                    {/*            className={`btn-clear`}*/}
                    {/*            onClick={() => {*/}
                    {/*              setShowDeleteModal(true);*/}
                    {/*              setSelectedStaffId(item.staffId);*/}
                    {/*            }}*/}
                    {/*          >*/}
                    {/*            <i className={`bi bi-trash`}></i>*/}
                    {/*          </button>*/}
                    {/*        </Table.DataCell>*/}
                    {/*      </Table.Row>*/}
                    {/*    ))}*/}
                    {/*  </Table.Body>*/}
                    {/*</Table>*/}
                    {/*)}*/}
                </div>
                <div className="text-center">
                    <button
                        className={`btn-clear`}
                        onClick={prevPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span>
            {" "}
                        Page {currentPage} of {totalPages}{" "}
          </span>
                    <button
                        className={`btn-clear`}
                        onClick={nextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>

                <Modal
                    size={"lg"}
                    show={showModalChangeRole}
                    onHide={() => setShowModalChangeRole(!showModalChangeRole)}
                    centered={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Action - Edit System Role</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="selected-highlight p-3">
                            Selected{" "}
                            {selectedEmployee.map((emp, i) => {
                                return (
                                    <React.Fragment>
                                    <span key={i} className="me-1">
                                        {emp.staffId} with role {emp.role},
                                    </span>
                                        <br/>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        <div>
                            <Row>
                                <Col xs={12} className="mt-4">
                                    <h6>Admin Role</h6>
                                </Col>
                                <Col xs={3}>Change role to</Col>
                                <Col xs={9} className="">
                                    <Select
                                        options={sysRolesList}
                                        onChange={(value) => handleChangeRole(value)}
                                        value={sysRole}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={`gap-2`}>
                        <Button
                            variant="outline-dark"
                            onClick={() => setShowModalChangeRole(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                // applyGroupEdit();
                                setShowModalChangeRole(false);
                                handleMultipleUpdate();
                            }}
                        >
                            Proceed
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div></div>
            </sgds-content-body>
            <CustomModal
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                action={handleActionDelete}
                title={`Delete staff`}
                description={`Delete Staff with ID: ${selectedStaffId}?`}
            />
            <CustomModal
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
                action={handleAddNewStaff}
                title={`Add staff`}
                description={
                    <React.Fragment>
                        <Form.Group className="mb-3" controlId="formControlWithIcon">
                            <Form.Label>Find Staff</Form.Label>
                            <Select options={staffList}
                                    onChange={(e) => {
                                        setSelectedStaffId(e?.value)
                                    }}
                                    onInputChange={(e) => {
                                        handleGetListStaff(e)
                                    }}
                            ></Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formControlWithIcon">
                            <Form.Label>Choose Role</Form.Label>
                            <Select options={sysRolesList} onChange={(e) => {
                                setSysRole(e)
                            }}></Select>

                        </Form.Group>
                    </React.Fragment>
                }
            />
        </Layout>
    );
}

export default SystemAdministration;
